@import '../colors';

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;

// fusv-disable
$grays: (
  "50":  $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// fusv-enable

// scss-docs-start colors-map
$colors: (
  "white": $white,
  "black": $black,

  "gray-50": $gray-50,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,

  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900,

  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900,

  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900,

  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900,

  "magenta-100": $magenta-100,
  "magenta-200": $magenta-200,
  "magenta-300": $magenta-300,
  "magenta-400": $magenta-400,
  "magenta-500": $magenta-500,
  "magenta-600": $magenta-600,
  "magenta-700": $magenta-700,
  "magenta-800": $magenta-800,
  "magenta-900": $magenta-900,

  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900,

  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,

  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900,

  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900,

  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900,

  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900,
) !default;
// scss-docs-end colors-map

// Theme colors
$primary:       $indigo !default;
$secondary:     $gray-300 !default;
$tertiary:      $pink !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $white !default;
$dark:          $gray-900 !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "white":      $white,
  "light":      $light,
  "dark":       $dark
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-map
$brand-colors: (
  "bootstrap":    $bootstrap,
  "laravel":      $laravel,
  "react":        $react,
  "facebook":     $facebook,
  "twitter":      $twitter,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "dribbble":     $dribbble,
  "github":       $github,
  "reddit":       $reddit
) !default;
// scss-docs-end brand-colors-map


// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`

$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;

// $gradient: linear-gradient(50deg, rgba($white, .15) 0, rgba($white, 0) 100%) !default;

// Bg Soft level

$bg-soft-level:           -80% !default;
$bg-shade-level:           15% !default;
$bg-tint-level:            15% !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-700 !default;
$body-text-align:           null !default;


// Surfaces
// Colors used for content area background colors

$surface-primary: $body-bg !default;
$surface-secondary: $gray-100 !default;
$surface-tertiary: $gray-50 !default;
$surface-light: $gray-200 !default;
$surface-dark: shade-color($dark, 7%) !default;

$surface-colors: () !default;
$surface-colors: map-merge((
  "surface-primary":      $surface-primary,
  "surface-secondary":    $surface-secondary,
  "surface-tertiary":     $surface-tertiary,
  "surface-light":        $surface-light,
  "surface-dark":         $surface-dark
), $surface-colors);

// RGB color maps used for generating utility classes
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;
$colors-rgb: map-loop($colors, to-rgb, "$value") !default;
$surface-colors-rgb: map-loop($surface-colors, to-rgb, "$value") !default;
