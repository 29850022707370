/*!
 * Webpixels CSS v1.2.6 (https://webpixels.io/)
 * Copyright 2023 Webpixels
 * Licensed under MIT (https://github.com/webpixels/css/blob/main/LICENSE)
 */

@import "base";
@import "forms";
@import "components";
@import "extras";

// Utilities
@import "utilities";
@import "bootstrap/scss/utilities/api";
